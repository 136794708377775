<template>
  <div class="form">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/sell-during" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent>
            <p class="promo promo_blocks">{{ "s2p10_text_1" | localize }}</p>

            <div class="form__group">
              <v-text-field
                v-model="old_price"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @input="checkForm($v.old_price)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('old_price', old_price)"
                :class="{
                  invalid: $v.old_price.$dirty && !$v.old_price.required,
                }"
                :hint="
                  ($v.old_price.$dirty && !$v.old_price.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :placeholder="'c_s1p1_placeholder' | localize"
                append-icon="icon-₪"
                ref="old_price"
                outlined
              ></v-text-field>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

const mortgage = VueCookies.get('mortgage') || {}

export default {
  name: 'Mortgage-old-realty-price-not-sold',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin, saveMixin],
  data: () => ({
    old_price: mortgage.old_price_not_sold || null
  }),
  validations: {
    old_price: { required }
  },
  created () {
    this.old_price = mortgage.old_price_not_sold ? this.formatNumber(mortgage.old_price_not_sold) : null
  },
  methods: {
    submitHandler () {
      this.old_price = this.parseNumber(this.old_price)

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      mortgage.old_price_not_sold = this.old_price
      this.$cookies.set('mortgage', mortgage)
      this.saveServiceInfo('mortgage', '/mortgage/old-mortgage-not-sold-realty', { potential_capital: this.old_price })
    }
  }
}
</script>
